


































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { RawLocation, Location } from 'vue-router'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { TrainerDocument } from '@/api/interfaces/trainerdocument'
import { Trainer } from '@/api/interfaces/trainer'
import { isReadonly } from '@/composables/UserGrant'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { TrainingType } from '@/api/interfaces/trainingtype'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'Trainer',
  components: {
    SelectSearchTrainingType,
    CancelUpdateDialog: () =>
    import('../../../components/cancel-update-dialog.vue'),
    AddDocuments: () =>
    import('../../../components/molecules/dialog/addDocuments.vue'),
    TopBar,
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
  },
  data () {
    return {
      dialogDocument: false,
      title: this.$t('trainer.title'),
      showChangePassword: false,
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields('trainerForm', [
      'trainer.civility',
      'trainer.lastName',
      'trainer.firstName',
      'trainer.corporateName',
      'trainer.address',
      'trainer.zipCode',
      'trainer.city',
      'trainer.areas',
      'trainer.phoneNumber',
      'trainer.secondPhoneNumber',
      'trainer.email',
      'trainer.badge',
      'trainer.trainerDocuments',
      'trainer.trainingType',
      'trainer.commentary',
      'trainer.password',
      'trainer.isActive',
      'trainer.regionManager',
    ]),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('trainingType', {
      listTrainingTypes: 'list',
    }),
    ...mapState('trainerForm', {
      trainer: 'trainer',
      isTrainerSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    listTrainingTypesFiltered (): Array<TrainingType> {
      if (Array.isArray(this.value)) {
        return (this.listTrainingTypes as Array<TrainingType>).filter(
          (x: TrainingType) => x.isActive || (this.value as Array<TrainingType>).some((y: TrainingType) => y['@id'] === x['@id']),
        )
      }

      if (typeof this.value === 'string') {
        return (this.listTrainingTypes as Array<TrainingType>).filter(
          (x: TrainingType) => x.isActive || x['@id'] === this.value,
        )
      }

      return (this.listTrainingTypes as Array<TrainingType>).filter(
        (x: TrainingType) => x.isActive || (this.value && x['@id'] === (this.value as TrainingType)['@id']),
      )
    },
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
    isActive: {
      handler (newVal) {
        if (newVal === false) {
          (this.trainer as Trainer).password = ''
        }
      },
    },
  },
  methods: {
    ...mapActions('trainerForm', {
      save: 'save',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    resetStore (to: Location) {
      if (!to.path || !to.path.startsWith('/trainer/')) {
        this.reset()
      }
    },
    async init () {
      this.resetTrainer()

      const idTrainer = this.$route.params.idTrainer
      if (idTrainer) {
        await this.loadTrainerById(idTrainer)
      }
    },
    ...mapActions('trainerForm', {
      loadTrainerById: 'loadById',
      resetTrainer: 'reset',
      deleteDocument: 'deleteDocument',
      downloadDocument: 'downloadDocument',
    }),
    cancel () {
      this.returnPrevious()
    },
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('trainer.form.saved')
        this.returnPrevious()
      }
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Trainer List' })
      }
    },
    deleteTrainerDocument (document: TrainerDocument): void {
      (this.trainerDocuments as Array<TrainerDocument>).splice(
        (this.trainerDocuments as Array<TrainerDocument>).indexOf(document),
        1,
      )
      this.deleteDocument(document.id as number)
    },
  },
})
