var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"trainerForm","color":"primary","type":"submit","loading":_vm.isTrainerSubmitted}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"trainer","fluid":"","tag":"section"}},[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"trainerForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-wallet-travel"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('trainer.form.title'))+" ")])],1),_c('v-row',{staticClass:"pt-7 pb-5"},[_c('v-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.civility'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"items":['M', 'Mme'],"label":_vm.$t('trainer.form.civility'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.civility),callback:function ($$v) {_vm.civility=$$v},expression:"civility"}})]}}])})],1),_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.lastName')+ '*',"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.firstName')+ '*',"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.corporateName'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.corporateName'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.corporateName),callback:function ($$v) {_vm.corporateName=$$v},expression:"corporateName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.address'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.address'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.zipCode'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"maxlength":"5","error-messages":errors,"label":_vm.$t('trainer.form.zipCode'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.city'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.city'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.area'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4 pt-0",attrs:{"multiple":"","chips":"","error-messages":errors,"items":_vm.listAreas,"item-value":"@id","item-text":function (item) { return _vm.$t('form.area', {
                    name: item.name,
                  }); },"label":_vm.$t('trainer.form.area'),"readonly":_vm.readonly()},model:{value:(_vm.areas),callback:function ($$v) {_vm.areas=$$v},expression:"areas"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3 mb-4","label":_vm.$t('trainer.form.phone'),"readonly":_vm.readonly()},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3 mb-4","label":_vm.$t('form.secondPhone'),"readonly":_vm.readonly()},model:{value:(_vm.secondPhoneNumber),callback:function ($$v) {_vm.secondPhoneNumber=$$v},expression:"secondPhoneNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.email'),"rules":_vm.trainer.password ? 'email|required' : 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.email'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.badge'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.badge'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.badge),callback:function ($$v) {_vm.badge=$$v},expression:"badge"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-btn',{staticClass:"ml-3 mb-2",attrs:{"mb":"40","color":"primary","readonly":_vm.readonly()},on:{"click":function($event){_vm.dialogDocument = true}}},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.addDocuments'))+" ")]),(
                  _vm.trainer.trainerDocuments &&
                    _vm.trainer.trainerDocuments.length > 0
                )?_c('v-simple-table',{staticClass:"ml-3 mb-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.documents_table.name'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.documents_table.type'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('trainer.form.documents_table.action'))+" ")])])]),_c('tbody',_vm._l((_vm.trainer.trainerDocuments),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.fileName))]),_c('td',[_vm._v(_vm._s(item.trainerDocumentType.type))]),_c('td',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","small":""}},[_c('v-icon',{on:{"click":function($event){return _vm.downloadDocument(item)}}},[_vm._v(" mdi-download ")])],1),(!_vm.readonly())?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red darken-2","small":""}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteTrainerDocument(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)])}),0)]},proxy:true}],null,false,1200872500)}):_vm._e()],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.eligibleTraining'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4 pt-0",attrs:{"error-messages":errors,"items":_vm.listTrainingTypesFiltered,"label":_vm.$t('trainer.form.eligibleTraining'),"multiple":"","attach":"","chips":"","item-value":"@id","item-text":"name","readonly":_vm.readonly()},model:{value:(_vm.trainingType),callback:function ($$v) {_vm.trainingType=$$v},expression:"trainingType"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-textarea',{staticClass:"ml-3 mb-4",attrs:{"label":_vm.$t('trainer.form.commentary'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})],1)],1)],2)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"formations","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-account-outline"}},[_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('trainer.form.title_access'))+" ")])],1),_c('v-row',{staticClass:"pt-7",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6","lg":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.username'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3",attrs:{"disabled":"","label":_vm.$t('trainer.form.username'),"error-messages":errors,"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('v-col',{attrs:{"md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.is_active'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-switch',{staticClass:"ml-5 mt-1",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.is_active'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})]}}])})],1),_c('v-col',{attrs:{"md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.is_region_manager'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-switch',{staticClass:"ml-5 mt-1",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.is_region_manager'),"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.regionManager),callback:function ($$v) {_vm.regionManager=$$v},expression:"regionManager"}})]}}])})],1)],1),(_vm.showChangePassword && _vm.isActive)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8","lg":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainer.form.password'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3",attrs:{"error-messages":errors,"label":_vm.$t('trainer.form.new_password'),"outlined":""},model:{value:(_vm.trainer.password),callback:function ($$v) {_vm.$set(_vm.trainer, "password", $$v)},expression:"trainer.password"}})]}}],null,false,1698735438)})],1)],1):_vm._e(),(!_vm.showChangePassword && _vm.isActive && !_vm.readonly())?_c('v-row',{staticClass:"py-5",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showChangePassword = true}}},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.update_password'))+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.access_informations'))+" ")])])],1)],2),_c('AddDocuments',{attrs:{"dialog":_vm.dialogDocument,"trainer":_vm.trainer},on:{"close":function($event){_vm.dialogDocument = false}}}),_c('cancel-update-dialog',{attrs:{"saving":_vm.isTrainerSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }